import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
//import ChairImage from "../components/chairimage"
import Image from "../components/chairimage"

const SecondPage = () => (
  <Layout>
    <SEO title="Congratulations" />
    <article className="done split">
      <div className="done__media">
        <Image></Image>
      </div>
      <section className="done__content large center">
        <h1>Congratulations!</h1>
        <h2>Your chair is now adjusted and ready for use.</h2>

        <p><a className="button" href="https://info.flokk.com/rh/new-logic" target="_blank">Read more about RH Logic</a></p>
      </section>
    </article>

    <Nav prevLink="/chapters/chapter_9-2" nextLink="" />
  </Layout>
)

export default SecondPage
